
import {
  computed, defineComponent, reactive,
} from 'vue';
import { Modal } from 'ant-design-vue';
import InscricoesPessoaModal from './InscricoesPessoaModal.vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import Icone from '@/core/components/Icone.vue';
import { IPessoaInscricoes } from '@/models/Entidades/Cadastros/Pessoas/IPessoaInscricoes';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import { ETipoOperacao } from '@/core/models/Enumeradores/ETipoOperacao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { useTelaBase } from '@/core/composables/TelaBase';
import ServicoPessoa from '@/servicos/Cadastros/Pessoas/ServicoPessoa';

export default defineComponent({
  name: 'InscricoesPessoa',
  props: {
    inscricoes: {
      type: Array as () => IPessoaInscricoes[],
      required: true,
    },
    relacoes: {
      type: Array as () => number[],
      required: true,
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
  },
  components: {
    Icone,
    InscricoesPessoaModal,
  },
  emits: ['update:inscricoes'],
  setup(props, { emit }) {
    const { montaOpcoesComListaItemGenerico } = useTelaBase();
    const servicoPessoa = new ServicoPessoa();
    const state = reactive({
      exibirInscricaoModal: false,
      inscricaoSelecionada: {} as IPessoaInscricoes,
      listaTiposInscricoes: [] as IOption[],
      colunasGrade: [] as IColumn[],
      windowWidth: (window.innerWidth - 100),
    });

    const computedInscricoespessoas = computed({
      get: () => props.inscricoes,
      set: (valor: IPessoaInscricoes[]) => {
        emit('update:inscricoes', valor);
      },
    });

    state.colunasGrade = [
      {
        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 0, visible: false, ellipsis: true,
      },
      {
        title: 'Tipo IE', dataIndex: 'tipoIE', key: 'TipoIE', position: 2, visible: true, width: 150, ordering: false, align: 'left', ellipsis: true,
      },
      {
        title: 'Inscrição Estadual', dataIndex: 'inscricaoEstadual', key: 'InscricaoEstadual', position: 3, visible: true, width: 175, ordering: false, align: 'left', ellipsis: true,
      },
      {
        title: 'Inscrição Municipal', dataIndex: 'inscricaoMunicipal', key: 'InscricaoMunicipal', position: 4, visible: true, ordering: false, align: 'left', width: 175, ellipsis: true,
      },
      {
        title: 'Suframa', dataIndex: 'suframa', key: 'InscricaoSuframa', position: 5, visible: true, ordering: false, align: 'left', width: 175, ellipsis: true,
      },
      {
        title: 'Tare', dataIndex: 'inscricaoTare', key: 'InscricaoTare', position: 6, visible: true, align: 'left', width: 175, ellipsis: true,
      },
      {
        title: 'ANTT', dataIndex: 'inscricaoAntt', key: 'InscricaoAntt', position: 7, visible: true, ordering: false, align: 'left', width: 175, ellipsis: true,
      },
      {
        title: 'Principal', dataIndex: 'principal', key: 'Principal', position: 8, visible: true, ordering: false, align: 'left', ellipsis: true,
      },
      {
        title: 'Ações', key: 'acoes', position: 1, visible: true, width: 70, align: 'center', ellipsis: true,
      },
    ];

    (async () => {
      const listaTiposInscricoes = await servicoPessoa.obterListaTipoInscricoesEstaduais();
      state.listaTiposInscricoes = montaOpcoesComListaItemGenerico(listaTiposInscricoes);
    })();

    async function salvarInscricoes(tipoOperacao:ETipoOperacao, inscricoes: IPessoaInscricoes) {
      console.log('chamou Salvar Inscricao');
      if (tipoOperacao === ETipoOperacao.Incluir) {
        console.log('chamou incluir inscricao');
        computedInscricoespessoas.value.push(inscricoes);
      } else if (tipoOperacao === ETipoOperacao.Alterar) {
        const indexInscricao = computedInscricoespessoas.value.findIndex((c) => c.codigo === inscricoes.codigo);
        if (indexInscricao >= 0) {
          computedInscricoespessoas.value[indexInscricao].codigo = inscricoes.codigo;
          computedInscricoespessoas.value[indexInscricao].tipoIe = inscricoes.tipoIe;
          computedInscricoespessoas.value[indexInscricao].inscricaoEstadual = inscricoes.inscricaoEstadual;
          computedInscricoespessoas.value[indexInscricao].inscricaoMunicipal = inscricoes.inscricaoMunicipal;
          computedInscricoespessoas.value[indexInscricao].inscricaoSuframa = inscricoes.inscricaoSuframa;
          computedInscricoespessoas.value[indexInscricao].inscricaoTare = inscricoes.inscricaoTare;
          computedInscricoespessoas.value[indexInscricao].inscricaoAntt = inscricoes.inscricaoAntt;
          computedInscricoespessoas.value[indexInscricao].principal = inscricoes.principal;
        }
      }
    }

    function novaInscricao() {
      state.inscricaoSelecionada = {} as IPessoaInscricoes;
      state.exibirInscricaoModal = true;
    }
    function editarInscricao(pessoaInscricao: IPessoaInscricoes) {
      state.inscricaoSelecionada = pessoaInscricao;
      state.exibirInscricaoModal = true;
    }

    function removerInscricao(codigoInscricao: number) {
      const index = computedInscricoespessoas.value.findIndex((c) => c.codigo === codigoInscricao);
      if (index !== -1) {
        computedInscricoespessoas.value.splice(index, 1);
      }
    }

    function confirmaExclusao(pessoaInscricao: IPessoaInscricoes) {
      Modal.confirm({
        title: 'Você deseja remover a inscrição:',
        content: `${pessoaInscricao.inscricaoEstadual} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: () => { removerInscricao(pessoaInscricao.codigo); },
      });
    }

    function obterDescricaoTipoIE(tipoIE: number): string {
      if (UtilitarioGeral.validaLista(state.listaTiposInscricoes)) {
        const tipoEncontrado = state.listaTiposInscricoes.find((c) => c.value === tipoIE);
        if (tipoEncontrado !== undefined) {
          return tipoEncontrado.label;
        }
      }

      return '';
    }

    return {
      props,
      computedInscricoespessoas,
      state,
      salvarInscricoes,
      novaInscricao,
      editarInscricao,
      confirmaExclusao,
      obterDescricaoTipoIE,
    };
  },
});
