
import { defineComponent, reactive, watch } from 'vue';
import { IPessoaInscricoes } from '@/models/Entidades/Cadastros/Pessoas/IPessoaInscricoes';
import { ETipoInscricaoEstadual } from '@/models/Enumeradores/Compartilhados/ETipoInscricaoEstadual';
import SelecionarTipoInscricaoEstadual from '@/components/Compartilhados/SelecionarTipoInscricaoEstadual.vue';
import Icone from '@/core/components/Icone.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { useModalBase } from '@/core/composables/ModalBase';
import { ETipoOperacao } from '@/core/models/Enumeradores/ETipoOperacao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'InscricoesPessoaModal',
  props: {
    visivel: {
      type: Boolean,
    },
    inscricoes: {
      type: Object as ()=> IPessoaInscricoes,
      required: true,
    },
  },
  components: {
    SelecionarTipoInscricaoEstadual,
    Icone,
    RequisicaoModal,
  },
  emits: ['update:visivel', 'salvarInscricoes'],
  setup(props, { emit }) {
    const { modalBase } = useModalBase(props, emit);

    const state = reactive({
      tipoOperacao: {} as ETipoOperacao,
      pessoaInscricoes: {} as IPessoaInscricoes,
    });

    function verificaPreenchimentoIE(tipoIE: number) {
      if (tipoIE === ETipoInscricaoEstadual.ContribuinteIsento || tipoIE === ETipoInscricaoEstadual.NaoContribuinte) {
        state.pessoaInscricoes.inscricaoEstadual = '';
      }
    }

    function salvarInscricoes() {
      emit('salvarInscricoes', state.tipoOperacao, state.pessoaInscricoes);
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      if (modalBase.computedVisivel) {
        if (props.inscricoes.codigo > 0) {
          state.pessoaInscricoes = UtilitarioGeral.instanciaObjetoLocal(props.inscricoes);
          state.tipoOperacao = ETipoOperacao.Alterar;
        } else {
          const pessoaInscricoes: IPessoaInscricoes = {} as IPessoaInscricoes;
          pessoaInscricoes.tipoIe = ETipoInscricaoEstadual.NaoContribuinte;
          pessoaInscricoes.principal = true;
          pessoaInscricoes.codigo = 0;
          state.pessoaInscricoes = pessoaInscricoes;
          state.tipoOperacao = ETipoOperacao.Incluir;
        }
      }
    });

    return {
      state,
      props,
      modalBase,
      ETipoInscricaoEstadual,
      verificaPreenchimentoIE,
      salvarInscricoes,
    };
  },
});
